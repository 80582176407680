import React from "react";
import { Link } from "gatsby";

export default function Tag({ tags, id }) {
  return (
    <>
      {tags.map((tag) => (
        <li key={id} className="px-1">
          <Link to={`/tags/${tag}`}>
            {tag}
          </Link>
        </li>
      ))}
    </>
  );
}
