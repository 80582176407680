import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Heading from "../components/Heading";
import BlogList from "../components/content/BlogList";
import RecentBlog from "../components/sections/sidebar-content/RecentBlog";
import Tag from "../components/sections/sidebar-content/Tag";
import Seo from "../components/SEO";
import { Helmet } from "react-helmet";

const BlogPage = ({ data }) => {
  const posts = data.allContentfulBlogPost.nodes;
  const recents = data.recentPostBlog.nodes;
  const tags = data.allTags.nodes;
  const heading = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <Helmet>
        <title>Blog | RöDigital</title>
      </Helmet>
      <Heading
        title="Nuestro Blog"
        subtitle="Todos los blogs que hemos publicado."
        image={heading.imagecontact.childImageSharp.gatsbyImageData}
      />
      <section className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              {posts.map((post) => (
                <BlogList
                  key={post.id}
                  title={post.title}
                  excerpt={post.excerpt.excerpt}
                  image={post.featuredImage.gatsbyImageData}
                  alt={post.featuredImage.title}
                  date={post.publishDate}
                  slug={post.slug}
                  tags={post.tags}
                />
              ))}
            </div>
            <div className="col-md-4">
              <div className="widget bg-light">
                <h5 className="mb-4">Lo más reciente</h5>
                {recents.map((recent) => (
                  <RecentBlog
                    key={recent.id}
                    title={recent.title}
                    image={recent.featuredImage.gatsbyImageData}
                    alt={recent.featuredImage.title}
                    date={recent.publishDate}
                    slug={recent.slug}
                  />
                ))}
              </div>
              <div className="widget bg-light">
                <h5 className="mb-4">Etiquetas</h5>
                <ul className="list-unstyled blog-tags m-0">
                  {tags.map((tag) => (
                    <Tag key={tag.id} tags={tag.tags} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BlogPage;

export const data = graphql

  `
  {
    allContentfulBlogPost {
      nodes {
        slug
        id
        title
        tags
        excerpt {
          excerpt
        }
        featuredImage {
          gatsbyImageData(formats: WEBP, width: 775, height: 515)
          title
        }
        publishDate(formatString: "MMMM D, YYYY", locale: "es")
      }
    }
    recentPostBlog: allContentfulBlogPost(
      sort: { fields: publishDate, order: DESC }
      limit: 3
    ) {
      nodes {
        id
        slug
        title
        featuredImage {
          gatsbyImageData(formats: WEBP, width: 775, height: 515)
          title
        }
        publishDate(formatString: "MMMM D, YYYY", locale: "es")
      }
    }
    allTags: allContentfulBlogPost {
      nodes {
        id
        tags
      }
    }
    markdownRemark {
      frontmatter {
        imagecontact {
          childImageSharp {
            gatsbyImageData(formats: WEBP)
          }
        }
        altimage
      }
    }
  }
`;

export const Head = () => <Seo title="Blog" />;
