import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export default function RecentBlog({ date, title, image, slug }) {
  return (
    <div className="recent-post d-flex">
      <GatsbyImage
        className="recent-post-image"
        style={{ width: "70%" }}
        image={image}
        alt="Blog imágen destacada"
      />
      <div className="text alt-font">
        <Link to={`/blog/${slug}`}>{title}</Link>
        <span className="date text-capitalize third-color">{date}</span>
      </div>
    </div>
  );
}
