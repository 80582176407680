import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export default function BlogList({
  date,
  title,
  excerpt,
  image,
  slug,
  tags,
}) {
  return (
    <div className="blog-list-item">
      <Link to={`/blog/${slug}`}>
        <div className="blog-item-img mb-5 hover-effect">
          <GatsbyImage image={image} alt="Blog imágen destacada"/>
        </div>
      </Link>

      <div className="blog-item-content">
        <div className="d-flex">
          <p className="third-color tex mb-0">
            {tags.map((tag) => (
              <span className="category text-capitalize" key={tag}>
                {tag}
              </span>
            ))}
          </p>
          <span className="mx-1"> - </span>
          <p className="date text-capitalize mb-0">{date}</p>
        </div>
        <h4 className="blog-title mt-2 mb-3">
          <Link key={slug} to={`/blog/${slug}`}>
            {title}
          </Link>
        </h4>
        <p className="mb-4">{excerpt}</p>
        <Link
          to={`/blog/${slug}`}
          className="btn btn-large btn-gradient btn-rounded"
        >
          Leer más
        </Link>
      </div>
    </div>
  );
}
